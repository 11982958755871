body {
    margin: 0;
    padding: 0;
    background-color: rgb(220, 220, 220) !important;

    -webkit-app-region: drag;
    width: 100vw;
    height: 100vh;

    font-family: "Nunito", --apple-default, sans-serif;

    overflow: hidden;
}

#root {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.rt-table .rt-tbody .rt-tr-group .rt-tr.-even {
    background-color: #f9f9f9;
}

.rt-table .rt-tbody .rt-tr-group .rt-tr:hover {
    background-color: #f5f5f5;
}

.cm-editor {
    height: 100%;
    font-size: 14px;
}