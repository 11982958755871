.svg {
    cursor: grab;

    &:active {
        cursor: grabbing;
    }

    .node {
        rect {
            fill: rgb(255, 255, 255);
            stroke-width: 1px;
        }

        &:hover rect {
            fill: rgb(190, 190, 190);
        }
    }

    .edgePath {
        &.edgeRef {
            stroke-dasharray: 5, 5;
        }

        path {
            stroke: rgb(0, 0, 0);
            stroke-width: 1px;
            stroke-linecap: square;
        }
    }
}
