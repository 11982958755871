/* -------------------------------------------------------------------- */
@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }  
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }  
    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};      
}


@include keyframes(fillingBoxSpinner-Rotation) {
    0%      { transform: rotate(0deg); }
    50%     { transform: rotate(90deg); }
    100%    { transform: rotate(90deg); }
}

@include keyframes(fillingBoxSpinner-Fill) {
    0%      { width: 0; }
    50%     { width: 0; }
    100%    { width: 100%; }
}

@include keyframes(fillingBoxSpinner-Clear) {
    0%      { width: 100%; }
    50%     { width: 100%; }
    100%    { width: 0; }
}

@include keyframes(pulsingCircleSpinner-Scaleout) {
    0% {
        transform: scale(1.0);
        opacity: 1.0;
    } 80% {
        transform: scale(1.0);
        opacity: 1.0;
    } 100% {
        transform: scale(0.0);
        opacity: 0.0;
    }
}

@include keyframes(rectangleWaveSpinner-Stretch) {
    0%, 40%, 100% {
        transform: scaleY(0.4);
//        -webkit-transform: scaleY(0.4);
    }  20% {
        transform: scaleY(1.0);
     //   -webkit-transform: scaleY(1.0);
    }
}


.fillingBoxSpinner {
    min-width: 8px;
    min-height: 8px;

    > .outline {
        /* Center in the spinner for rotation */
        position: relative;
        height: 100%;
        width: 100%;
        border: 1px solid rgb(255, 255, 255);
        box-sizing: border-box;

        > .box {
            /* Variable height fill */
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            height: 100%;
            background-color: rgb(255, 255, 255);
        }

    }
    > .state0 {
            @include animation('fillingBoxSpinner-Rotation 1.2s ease');

            > .box {
                width: 100%;
                @include animation('fillingBoxSpinner-Fill 1.2s linear');
            }
        }
    > .state1 {
            @include animation('fillingBoxSpinner-Rotation 1.2s ease');

            > .box {
                width: 0;
                @include animation('fillingBoxSpinner-Clear 1.2s linear');
            }
        }
}



/* -------------------------------------------------------------------- */

.pulsingCircleSpinner {
    min-width: 8px;
    min-height: 8px;

    > .outline {
        height: 100%;
        width: 100%;
        border-radius: 100%;
        box-sizing: border-box;
        border: 1px solid rgb(200, 212, 227);
        
        > .circle {
            height: 100%;
            width: 100%;
            border-radius: 100%;
            background-color: rgb(212, 115, 62);
            opacity: 0.0;
        }
    }

    > .state1 {
            > .circle {
                @include animation('pulsingCircleSpinner-Scaleout 1.2s ease-in');
            }
    > .state0 {
            > .circle {
                @include animation('pulsingCircleSpinner-Scaleout 1.2s ease-in');
            }   
        }
    }
}

/* -------------------------------------------------------------------- */



.rectangleWaveSpinner {
    display: flex;
    flex-direction: row;
    
    > div {
        flex: 1 0 auto;
        margin: 0px 3%;
        height: 100%;
    
        @include animation('rectangleWaveSpinner-Stretch 1.2s infinite ease-in-out')
    }

    > .rect2 {
        -webkit-animation-delay: -1.1s !important;
        animation-delay: -1.1s !important;
    }

    > .rect3 {
        -webkit-animation-delay: -1.0s !important;
        animation-delay: -1.0s !important;
    }

    > .rect4 {
        -webkit-animation-delay: -0.9s !important;
        animation-delay: -0.9s !important;
    }

    > .rect5 {
        -webkit-animation-delay: -0.8s !important;
        animation-delay: -0.8s !important;
    }

}

/* -------------------------------------------------------------------- */