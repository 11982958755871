@mixin controlelement {
    margin: 6px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.SQLLab {
    position: relative;
    display: grid;
    height: 100%;
    grid-template-rows: 0 calc(100% - 48px) 48px;

    >.settings {
        grid-row: 2;
        display: grid;
        align-content: start;
        justify-content: center;
        > select {
            width: 100%;
            height: 32px;
            padding: 4px;
            margin: 5px;
        }
    }

    >.editor {
        grid-row: 2;
    }

    >.results {
        grid-row: 2;

        & .content {
            height: 100%;

            display: grid;
            grid-template-rows: 32px calc(100% - 32px);

            >.header {
                grid-row: 1;

                background-color: rgb(220, 220, 220);
                box-sizing: border-box;
                border-bottom: 1px solid rgb(200, 200, 200);

                >.title {
                    font-weight: 500;
                    line-height: 32px;
                    text-align: center;
                }
            }

            >.body {
                grid-row: 2;
                width: 100%;
                overflow: auto;

                & .dataTable {
                    width: 100%;
                    height: 100%;
                    border: none !important;
                    background: rgb(255, 255, 255);

                    &.rthead {
                        overflow-y: scroll;
                    }

                    &.rt-thead.-header {
                        box-shadow: none !important;
                        border-bottom: 1px solid rgb(200, 200, 200);
                        box-sizing: border-box;
                    }

                    &.-pagination {
                        box-shadow: none !important;
                        border-top: 1px solid rgb(200, 212, 227);
                        box-sizing: border-box;
                    }
                }

                >.errorCat {
                    overflow: hidden;
                    height: 100%;
                    background-color: #ccc;

                    display: grid;
                    grid-template-columns: 100%;
                    grid-template-rows: auto 40% 64px auto;
                }

                >.giveSQL {
                    overflow: hidden;
                    height: 100%;
                    background-color: #ccc;
                    display: grid;
                    grid-template-columns: 100%;
                    grid-template-rows: auto 50% 64px auto;
                }

                & .logo {
                    grid-row: 2;
                    align-self: center;
                    justify-self: center;
                    height: 100%;
                }

                & .text {
                    grid-row: 3;
                    align-self: center;
                    justify-self: center;
                    font-weight: 500;
                    font-size: 18px;
                    color: rgb(100, 100, 100);

                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    >.play {
                        display: inline-block;
                        margin: 4px 2px 0 2px;
                    }
                }
            }
        }
    }

    >.controls {
        grid-row: 3;
        height: 100%;
        box-sizing: border-box;
        background-color: #1e1e1e;

        display: grid;
        grid-template-columns: 10px 48px 48px auto 48px 48px auto 48px 48px 48px 10px;
        grid-template-rows: 1;

        >.themebutton {
            @include controlelement;
            grid-column: 9;
            grid-row: 1;
            background-color: rgb(62, 61, 50);

            &:hover {
                background-color: rgb(212, 115, 62);
            }
        }

        >.runbutton {
            @include controlelement;
            grid-column: 10;
            grid-row: 1;
            background-color: rgb(62, 61, 50);

            &:hover {
                background-color: rgb(212, 115, 62);
            }
        }

        & .tab {
            @include controlelement;

            &:hover {
                background-color: rgb(62, 61, 50);
            }
        }

        & .tabActive {
            background-color: rgb(62, 61, 50);
        }

    }

    >.queryStats {
        grid-column: 2;
        background-color: rgb(220, 220, 220) !important;

        box-sizing: border-box;
        border-left: 1px solid rgb(200, 200, 200);

        display: grid;
        grid-template-rows: 32px auto;

        >.header {
            grid-row: 1;

            background-color: rgb(220, 220, 220) !important;
            box-sizing: border-box;
            border-bottom: 1px solid rgb(200, 200, 200);


            >.title {
                font-weight: 500;
                line-height: 32px;
                text-align: center;
            }
        }


        .table {
            grid-row: 2;

            padding: 16px 16px 14px 16px;
            display: grid;
            grid-template-columns: 104px auto;
            grid-template-rows: 22px;
            grid-column-gap: 2px;
            grid-row-gap: 6px;
            line-height: 22px;
            margin-bottom: auto;

            .label {
                grid-column: 1;
            }

            .value {
                grid-column: 2;
                text-align: end;
            }

            .valueHighlighted {
                grid-column: 2;
                text-align: center;
                border-radius: 4px;
                color: white;
                background-color: rgb(62, 61, 50);
            }
        }
    }
}