.nodeHeader {
    font-weight: bold;
    text-align: center;
    font-size: medium;
}

.nodeBody {
    font-weight: normal;
    text-align: left;
    font-size: small;
    white-space: pre-line;
}
