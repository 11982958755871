.Router {
    height: 100%;
    width: 100%;

    display: grid;
    grid-template-columns: 100%;

    > .NavigationBarContainer {
        grid-row: 1;
    }
    
    > .SQLLabContainer {
        grid-row: 2;
    }

    > .StatusBarContainer {
        grid-row: 3;
    }

}
