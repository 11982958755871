@import 'variables';

.queryPlan {
    height: 100%;

    display: grid;
    grid-template-rows: 0px calc(100% - 40px) 40px;

    >.graph {
        grid-row: 2;
    }

    >.zoomButtons {
        grid-row: 3;
        align-self: end;
        justify-self: end;
        width: 100%;

        display: grid;
        grid-template-columns: 10px 200px auto 48px 48px 10px;

        margin-bottom: 8px;
        margin-right: 8px;
    }

    .button {
        background-color: $button-primary-color;
        margin-top: 5px;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 5px;
        user-select: none;
        height: 28px;
        text-align: center;
        flex: 1 0 auto;
        margin: 0 4px;

        &:not(.disabled):hover {
            background-color: $button-secondary-color;
        }

        &.active {
            background-color: $button-secondary-color;
        }

        &.fetchButton {
            grid-column: 2;
            width: 200px;
        }

        &.zoomInButton {
            grid-column: 4;
            width: 40px;
        }

        &.zoomOutButton {
            grid-column: 5;
            width: 40px;
        }
    }
}