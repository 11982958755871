/* -------------------------------------------------------------------- */

@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 300;
    src: url(./fonts/Nunito-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    src: url(./fonts/Nunito-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    src: url(./fonts/Nunito-SemiBold.ttf) format('truetype');
}

@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    src: url(./fonts/Nunito-Bold.ttf) format('truetype');
}

/* -------------------------------------------------------------------- */
