@mixin selector {
    position: absolute;
    right:  50px;
    width: 200px;

    display: grid;

    .dropdownButton {
        position: relative;
        width: 100%;
        color: white;
        padding: 8px 16px;
        border-radius: 4px;
        border: none;
        cursor: pointer;

        background-color: #1e1e1e;

        &:hover {
            background-color: rgb(212, 115, 62);
        }

    }

    .dropdownContent {
        position: absolute;
        top: 0;
        right: 0;
        width: 200px;
        background-color: #f9f9f9;
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
        max-height: 390px;
        overflow-x: hidden;
        overflow-y: auto;
        z-index: 100;
        cursor: pointer;

        > div {
            padding: 12px 16px;
            text-decoration: none;
            cursor: default;
            user-select: none;

            &:hover {
                background-color: #f1f1f1;
            }
        }
    }
}

.SQLLab {
    position: relative;
    height: 100%;

    & .queryPrompt {
        position: relative;
        width: 100%;
        height: 100%;

        display: grid;
        grid-template-columns: 48px auto;

        > .controls {
            grid-column: 1;
            box-sizing: border-box;
            background-color: #1e1e1e;

            display: grid;
            grid-template-columns: 1;
            grid-template-rows: 48px auto 48px 48px 16px;

            > .runbutton {
                grid-column: 1;
                grid-row: 1;
                cursor: pointer;

                margin: 6px;
                border-radius: 4px;
                background-color: rgb(62, 61, 50);
                display: flex;
                justify-content: center;
                align-items: center;

                &:hover {
                    background-color: rgb(212, 115, 62);
                }
            }

            > .themebutton {
                grid-column: 1;
                grid-row: 3;
                cursor: pointer;

                margin: 6px;
                border-radius: 4px;
                background-color: rgb(62, 61, 50);
                display: flex;
                justify-content: center;
                align-items: center;

                &:hover {
                    background-color: rgb(212, 115, 62);
                }
            }

            > .infobutton {
                grid-column: 1;
                grid-row: 4;
                cursor: pointer;

                margin: 6px;
                border-radius: 4px;
                background-color: rgb(62, 61, 50);
                display: flex;
                justify-content: center;
                align-items: center;

                &:hover {
                    background-color: rgb(212, 115, 62);
                }

                > .tooltip{
                    width: 300px;
                    opacity: 1;
                    text-align: left;
                }
            }

        }
        > .editor {
            grid-column: 2;
        }


        > .querySelector {
            @include selector;
            top: 16px;
        }

        > .schemaSelector {
            @include selector;
            top: 56px;
        }

        > .scaleSelector {
            @include selector;
            top: 96px;
        }

        > .skewSelector {
            @include selector;
            top: 136px;
        }

        > .dropdownContent {
            position: absolute;
            top: 16px;
            right: 50px;
            width: 200px;
            background-color: #f9f9f9;
            box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
            max-height: calc(100% - 16px);
            overflow-x: hidden;
            overflow-y: auto;
            z-index: 100;

            > div {
                padding: 12px 16px;
                text-decoration: none;
                cursor: pointer;
                user-select: none;

                &:hover {
                    background-color: #f1f1f1;
                }
            }
        }
    }

    & .results {
        height: 100%;

        display: grid;
        grid-template-columns: auto min-content;
        grid-template-rows: 100%;

        > .inspector {
            grid-column: 1;
            height: 100%;

            display: grid;
            grid-template-columns: 48px auto;
            grid-template-rows: 100%;

            > .sidebar {
                grid-column: 1;
                grid-row-start: 1;
                grid-row-end: 2;
                background-color: #1e1e1e;

                display: grid;
                grid-template-columns: 1;
                grid-template-rows: 48px 48px 48px auto;

                & .tab {
                    margin: 6px;
                    border-radius: 4px;

                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &:hover {
                        background-color: rgb(62, 61, 50);
                    }
                    //nochmal anschauen...

                }

                & .tabActive {
                    background-color: rgb(62, 61, 50);
                }


            }

            & .content {
                grid-column: 2;
                height: 100%;

                display: grid;
                grid-template-rows: 32px calc(100% - 32px);

                > .header {
                    grid-row: 1;

                    background-color: rgb(220, 220, 220);
                    box-sizing: border-box;
                    border-bottom: 1px solid rgb(200, 200, 200);

                    > .title {
                        font-weight: 500;
                        line-height: 32px;
                        text-align: center;
                    }
                }

                > .body {
                    grid-row: 2;

                    & .dataTable {
                        width: 100%;
                        height: 100%;
                        border: none !important;
                        background: rgb(255, 255, 255);

                        &.rthead {
                            overflow-y: scroll;
                        }

                        &.rt-thead.-header {
                            box-shadow: none !important;
                            border-bottom: 1px solid rgb(200, 200, 200);
                            box-sizing: border-box;
                        }

                        &.-pagination {
                            box-shadow: none !important;
                            border-top: 1px solid rgb(200, 212, 227);
                            box-sizing: border-box;
                        }
                    }

                    > .errorCat {
                        overflow: hidden;
                        height: 100%;
                        background-color: #ccc;

                        display: grid;
                        grid-template-columns: 100%;
                        grid-template-rows: auto 40% 64px auto;
                    }

                    > .giveSQL {
                        overflow: hidden;
                        height: 100%;
                        background-color: #ccc;
                        display: grid;
                        grid-template-columns: 100%;
                        grid-template-rows: auto 50% 64px auto;
                    }

                    & .logo {
                        grid-row: 2;
                        align-self: center;
                        justify-self: center;
                        height: 100%;
                    }

                    & .text {
                        grid-row: 3;
                        align-self: center;
                        justify-self: center;
                        font-weight: 500;
                        font-size: 18px;
                        color: rgb(100, 100, 100);

                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        > .play {
                            display: inline-block;
                            margin: 4px 2px 0 2px;
                        }

                    }

                }



            }


        }


        > .queryStats {
            grid-column: 2;
            background-color: rgb(220, 220, 220) !important;

            box-sizing: border-box;
            border-left: 1px solid rgb(200, 200, 200);

            display: grid;
            grid-template-rows: 32px auto;

            > .header {
                grid-row: 1;

                background-color: rgb(220, 220, 220) !important;
                box-sizing: border-box;
                border-bottom: 1px solid rgb(200, 200, 200);


                > .title {
                    font-weight: 500;
                    line-height: 32px;
                    text-align: center;
                }
            }


            .table {
                grid-row: 2;

                padding: 16px 16px 14px 16px;
                display: grid;
                grid-template-columns: 104px auto;
                grid-template-rows: 22px;
                grid-column-gap: 2px;
                grid-row-gap: 6px;
                line-height: 22px;
                margin-bottom: auto;

                .label {
                    grid-column: 1;
                }

                .value {
                    grid-column: 2;
                    text-align: end;
                }

                .valueHighlighted {
                    grid-column: 2;
                    text-align: center;
                    border-radius: 4px;
                    color: white;
                    background-color: rgb(62, 61, 50);
                }
            }
        }
    }
}



