.navigationBar {
    display: grid;
    height: 100%;
    width: 100%;

    grid-template-columns: 48px 200px auto;

    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);

    > .logo {
        grid-column: 1;
        align-self: center;
        justify-self: center;
    
        width: 24px;
        height: 24px;
    }
    > .name {
        grid-column: 2;
        align-self: center;
        justify-self: start;
        padding-left: 8px;
    }
}