@import 'variables';

.detailComponent {
    padding-top: 12px;
    text-align: left;
    z-index: 1;
    word-wrap: normal;
    width: auto;
    overflow-x: hidden;
    vertical-align: top;
    height: 100%;

    > .Title {
        font-weight: bold;
        display: block;
        text-align: center;
        margin: 0 auto;
    }

    > .Body {
        font-size: 12px;
        padding: 20px 14px 14px 20px;
        display: grid;
        grid-template-columns: 80px 110px;
        grid-template-rows: 22px;
        grid-column-gap: 16px;
        grid-row-gap: 8px;
        line-height: 22px;

        > .ItemLabel {
            grid-column: 1;
            overflow: hidden;
            hyphens: manual;
        }

        > .ItemValue {
            grid-column: 2;
            align-self: end;
        }

        > .Buttons {
            display: flex;
            user-select: none;

            > .collapse {
                color: rgb(255, 255, 255);
                text-align: center;
                background-color: $button-primary-color;
                margin-top: 5px;
                margin-bottom: 5px;
                border-radius: 5px;
                padding-right: 4px;
                padding-left: 4px;

                &.disabled {
                    background-color: $button-disabled-color;
                    pointer-events: none;
                    cursor: default;
                }

                &:not(.disabled):hover {
                    background-color: $button-secondary-color;
                }
            }
        }
    }
}
