

.serverSelector {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-color: #1e1e1e;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


    & .hireText {
        position: absolute;
        top: 16px;
        right: 4px;
        font-size: 14px;
        font-weight: 500;

        transform: rotate(45deg);
        color: rgb(42, 63, 95);
    }
    & .hireBackground {
        position: absolute;
        top: 0;
        right: 0;
        border-style: solid;
        border-width: 0 76px 76px 0;
        border-color: transparent rgb(255, 255, 255) transparent transparent;
    }

    > .header {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        > .logo {
            width: 40px;
            height: 40px;
            margin: 8px 24px 8px 0px;
        }

        > .name {
            font-family: "Nunito", sans-serif;
            font-size: 30px;
            font-weight: 500;
            color: rgb(255, 255, 255);
            letter-spacing: 0.5px;
            line-height: 32px;
        }

    }

    > .version {
        position: absolute;
        bottom: 16px;
        left: 16px;
        font-size: 14px;
        color: rgb(255, 255, 255);
    }


    > .listContainer {
        margin-top: 24px;
        margin-bottom: 16px;
        width: 300px;
        background-color: rgb(255, 255, 255);
        border-radius: 4px;
    
    
        > .header {
            font-weight: 500;
            letter-spacing: 0.5px;
            text-align: left;
        
            color: rgb(42, 63, 95);
            border-radius: 4px 4px 0px 0px;
        
            background-color: rgb(220, 220, 220);
            padding: 4px 12px;
            box-sizing: border-box;
            border-bottom: 1px solid rgb(200, 200, 200);
        }

        > .content {
            
            > .entry {
                display: grid;
                grid-template-columns: 40px 72px auto 40px;
                grid-column-gap: 8px;
                color: rgb(42, 63, 95);

                > .border {
                    grid-row: 2;
                    grid-column: 2 / span 3;
                    border-bottom: 1px solid rgb(200, 212, 227);
                }

                > .status {
                    grid-row: 1 / span 2;
                    grid-column: 1;

                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
 
                    > div {
                       width: 16px;
                       height: 16px;
                    }
                   

                }

                > .hostname {
                    grid-row: 1;
                    grid-column: 2 / span 2;
                    padding: 4px 0px 0px 0px;
                    font-size: 14px;
                    font-weight: 500;
                }
                
                > .protocol {
                    grid-row: 2;
                    grid-column: 2;
                    font-size: 12px;
                    line-height: 24px;
                    padding: 0px 0px 4px 0px;
                }
                
                > .port {
                    grid-row: 2;
                    grid-column: 3;
                    font-size: 12px;
                    line-height: 24px;
                    padding: 0px 0px 4px 0px;
                }
                
                > .arrow {
                    grid-row: 1 / span 2;
                    grid-column: 4;
                
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                
                    margin-bottom: 1px;
                
                    &:hover {
                        background-color: rgb(245, 245, 245);
                    }
                }
            }

        }

    }
}



