.statusBar {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: row;        
    justify-content: space-between;
    background-color: rgb(212,115,62);
    color: rgb(255, 255, 255);

    font-weight: 400;
    font-size: 9pt;

    > .left {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;

        &:hover {
            background-color: rgb(166, 88, 46);
        }

        > .server {
            display: flex;
            flex-direction: row;
            align-items: center;
    
            padding-right: 12px;
    
            & .subComponent {
                padding-left: 8px;
                padding-right: 8px;
            }
        }
    }

    & .logStats {
        display: flex;
        flex-direction: row;
        height: 100%;
        cursor: pointer;

        &:hover {
            background-color: rgb(166, 88, 46);
        }

        > .logStat {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 100%;
            padding-left: 12px;
            padding-right: 12px;

            > .name {
                padding-left: 4px;
                padding-right: 4px;
            }
            
            > .icon {
                padding-left: 4px;
                padding-right: 4px;
                width: 13px;
                height: 13px;
            }

        }
    }

    & .logStatsActive {
        background-color: rgb(166, 88, 46);
    }


    > .logViewer {
        position: absolute;
        top: -300px;
        right: 0px;
        width: 600px;
        height: 300px;
    }



}