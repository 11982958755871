.logViewer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    border-radius: 2px;

    > .header {
        width: 100%;
        height: 24px;
    
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    
        border-radius: 2px;
        background-color: rgb(212,115,62);


        > .title {
            margin-left: 12px;
            font-weight: 400;
            font-size: 10pt;
            line-height: 24px;
        }

        > .close {
            height: 24px;
            width: 24px;
        
            display: flex;
            justify-content: center;
            align-items: center;
        }


    }

    > .entriesScroller {
        position: relative;
        width: 100%;
        flex-grow: 1;
        padding: 2px 2px;
    
        font-weight: 400;
        font-size: 9pt;
        line-height: 1.2;
    
        background-color: rgb(166, 88, 46);

        & .entries {
            width: 100%;
            border-collapse: collapse;
            text-align: left;


            > .entry {
                display: flex;
                flex-direction: row;
                width: 100%;

                
                > .timestamp {
                    flex: 0 0 calc(8ch + 8px);
                
                    padding: 4px 4px;
                
                    overflow: hidden;
                    text-overflow: ellipsis;
                    text-align: center;
                
                    border: 1px solid rgb(166, 88, 46);
                    background-color: rgb(146, 79, 42);
                }


                > .logLevel {
                    flex: 0 0 calc(8ch + 8px);

                    padding: 4px 4px;

                    overflow: hidden;
                    text-overflow: ellipsis;
                    text-align: center;
                    font-weight: 500;

                    color: rgba(255, 255, 255, 0.5);
                    border: 1px solid rgb(166, 88, 46);
                    background-color: rgb(146, 79, 42);
                }

                > .text {
                    flex: 1 1 0px;

                    padding: 4px 4px;
                    border: 1px solid rgb(166, 88, 46);
                    background-color: rgb(146, 79, 42);
                }
            }
        }
    }
}